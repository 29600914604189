<template>
  <div class="ud-body">
    <a-card :bordered="true">
      <!-- 微信小程序端 -->
      <div class="payment-item">
        <div class="payment-client">
          <div class="client">{{ form['mpWeixin'].name }}</div>
          <div class="desc">{{ form['mpWeixin'].title }}</div>
        </div>
        <div class="payment-method">
          <a-table
            :bordered="true"
            :pagination="false"
            :columns="columns"
            :dataSource="form['mpWeixin'].methods"
            row-key="paymentId"
          >
            <template #isMustTemplate="{ record }">
              <div v-if="record.isMustTemplate">
                <a-select
                  style="width: 250px"
                  v-model:value="record.templateId"
                >
                  <a-select-option
                    v-for="(item, index) in wechatTemplateList"
                    :key="index"
                    :value="item.value"
                    >{{ item.label }}</a-select-option
                  >
                </a-select>
                <span
                  @click="openEdit()"
                  style="color: #1890ff; margin-left: 8px; cursor: default"
                  >新增模板</span
                >
              </div>
              <div v-else style="color: #999999">无需支付模板</div>
            </template>
            <template #method="{ record }">
              <div>
                <img
                  v-if="record.method === 'wechat'"
                  style="width: 25px; height: 25px"
                  src="@/assets/weixin.png"
                  alt=""
                />
                <img
                  v-if="record.method === 'balance'"
                  style="width: 25px; height: 25px"
                  src="@/assets/balance.png"
                  alt=""
                />
                <img
                  v-if="record.method === 'alipay'"
                  style="width: 25px; height: 25px"
                  src="@/assets/alipay.png"
                  alt=""
                />
                <span style="margin-left: 8px">{{ record.method==='wechat'?'微信支付':record.method==='alipay'?'支付宝支付':'余额支付' }}</span>
              </div>
            </template>
            <template #isEnable="{ record }">
              <a-switch
                :unCheckedValue="0"
                :checkedValue="1"
                v-model:checked="record.isEnable"
              ></a-switch>
            </template>
            <template #isDefault="{ record }">
              <a-switch
                :unCheckedValue="0"
                :checkedValue="1"
                v-model:checked="record.isDefault"
              ></a-switch>
            </template>
          </a-table>
        </div>
      </div>

      <!-- H5端 -->
      <div class="payment-item">
        <div class="payment-client">
          <div class="client">{{ form['h5'].name }}</div>
          <div class="desc">{{ form['h5'].title }}</div>
        </div>
        <div class="payment-method">
          <a-table
            :bordered="true"
            :pagination="false"
            :columns="columns"
            :dataSource="form['h5'].methods"
            row-key="paymentId"
          >
            <template #isMustTemplate="{ record }">
              <div v-if="record.isMustTemplate">
                <a-select
                  v-if="record.method === 'wechat'"
                  style="width: 250px"
                  v-model:value="record.templateId"
                >
                  <a-select-option
                    v-for="(item, index) in wechatTemplateList"
                    :key="index"
                    :value="item.value"
                    >{{ item.label }}</a-select-option
                  >
                </a-select>
                <a-select
                  v-else
                  style="width: 250px"
                  v-model:value="record.templateId"
                >
                  <a-select-option
                    v-for="(item, index) in alipayTemplateList"
                    :key="index"
                    :value="item.value"
                    >{{ item.label }}</a-select-option
                  >
                </a-select>
                <span
                  @click="openEdit()"
                  style="color: #1890ff; margin-left: 8px; cursor: default"
                  >新增模板</span
                >
              </div>
              <div v-else style="color: #999999">无需支付模板</div>
            </template>
            <template #method="{ record }">
              <div>
                <img
                  v-if="record.method === 'wechat'"
                  style="width: 25px; height: 25px"
                  src="@/assets/weixin.png"
                  alt=""
                />
                <img
                  v-if="record.method === 'balance'"
                  style="width: 25px; height: 25px"
                  src="@/assets/balance.png"
                  alt=""
                />
                <img
                  v-if="record.method === 'alipay'"
                  style="width: 25px; height: 25px"
                  src="@/assets/alipay.png"
                  alt=""
                />
                <span style="margin-left: 8px">{{ record.method==='wechat'?'微信支付':record.method==='alipay'?'支付宝支付':'余额支付' }}</span>
              </div>
            </template>
            <template #isEnable="{ record }">
              <a-switch
                :unCheckedValue="0"
                :checkedValue="1"
                v-model:checked="record.isEnable"
              ></a-switch>
            </template>
            <template #isDefault="{ record }">
              <a-switch
                :unCheckedValue="0"
                :checkedValue="1"
                v-model:checked="record.isDefault"
              ></a-switch>
            </template>
          </a-table>
        </div>
      </div>

      <!-- 微信公众号 -->
      <div class="payment-item">
        <div class="payment-client">
          <div class="client">{{ form['h5Weixin'].name }}</div>
          <div class="desc">{{ form['h5Weixin'].title }}</div>
        </div>
        <div class="payment-method">
          <a-table
            :bordered="true"
            :pagination="false"
            :columns="columns"
            :dataSource="form['h5Weixin'].methods"
            row-key="paymentId"
          >
            <template #isMustTemplate="{ record }">
              <div v-if="record.isMustTemplate">
                <a-select
                  v-if="record.method === 'wechat'"
                  style="width: 250px"
                  v-model:value="record.templateId"
                >
                  <a-select-option
                    v-for="(item, index) in wechatTemplateList"
                    :key="index"
                    :value="item.value"
                    >{{ item.label }}</a-select-option
                  >
                </a-select>
                <a-select
                  v-else
                  style="width: 250px"
                  v-model:value="record.templateId"
                >
                  <a-select-option
                    v-for="(item, index) in alipayTemplateList"
                    :key="index"
                    :value="item.value"
                    >{{ item.label }}</a-select-option
                  >
                </a-select>
                <span
                  @click="openEdit()"
                  style="color: #1890ff; margin-left: 8px; cursor: default"
                  >新增模板</span
                >
              </div>
              <div v-else style="color: #999999">无需支付模板</div>
            </template>
            <template #method="{ record }">
              <div>
                <img
                  v-if="record.method === 'wechat'"
                  style="width: 25px; height: 25px"
                  src="@/assets/weixin.png"
                  alt=""
                />
                <img
                  v-if="record.method === 'balance'"
                  style="width: 25px; height: 25px"
                  src="@/assets/balance.png"
                  alt=""
                />
                <img
                  v-if="record.method === 'alipay'"
                  style="width: 25px; height: 25px"
                  src="@/assets/alipay.png"
                  alt=""
                />
                <span style="margin-left: 8px">{{ record.method==='wechat'?'微信支付':record.method==='alipay'?'支付宝支付':'余额支付' }}</span>
              </div>
            </template>
            <template #isEnable="{ record }">
              <a-switch
                :unCheckedValue="0"
                :checkedValue="1"
                v-model:checked="record.isEnable"
              ></a-switch>
            </template>
            <template #isDefault="{ record }">
              <a-switch
                :unCheckedValue="0"
                :checkedValue="1"
                v-model:checked="record.isDefault"
              ></a-switch>
            </template>
          </a-table>
        </div>
      </div>

      <!-- APP端 -->
      <div class="payment-item">
        <div class="payment-client">
          <div class="client">{{ form['app'].name }}</div>
          <div class="desc">{{ form['app'].title }}</div>
        </div>
        <div class="payment-method">
          <a-table
            :bordered="true"
            :pagination="false"
            :columns="columns"
            :dataSource="form['app'].methods"
            row-key="paymentId"
          >
            <template #isMustTemplate="{ record }">
              <div v-if="record.isMustTemplate">
                <a-select
                  v-if="record.method === 'wechat'"
                  style="width: 250px"
                  v-model:value="record.templateId"
                >
                  <a-select-option
                    v-for="(item, index) in wechatTemplateList"
                    :key="index"
                    :value="item.value"
                    >{{ item.label }}</a-select-option
                  >
                </a-select>
                <a-select
                  v-else
                  style="width: 250px"
                  v-model:value="record.templateId"
                >
                  <a-select-option
                    v-for="(item, index) in alipayTemplateList"
                    :key="index"
                    :value="item.value"
                    >{{ item.label }}</a-select-option
                  >
                </a-select>
                <span
                  @click="openEdit()"
                  style="color: #1890ff; margin-left: 8px; cursor: default"
                  >新增模板</span
                >
              </div>
              <div v-else style="color: #999999">无需支付模板</div>
            </template>
            <template #method="{ record }">
              <div>
                <img
                  v-if="record.method === 'wechat'"
                  style="width: 25px; height: 25px"
                  src="@/assets/weixin.png"
                  alt=""
                />
                <img
                  v-if="record.method === 'balance'"
                  style="width: 25px; height: 25px"
                  src="@/assets/balance.png"
                  alt=""
                />
                <img
                  v-if="record.method === 'alipay'"
                  style="width: 25px; height: 25px"
                  src="@/assets/alipay.png"
                  alt=""
                />
                <span style="margin-left: 8px">{{ record.method==='wechat'?'微信支付':record.method==='alipay'?'支付宝支付':'余额支付' }}</span>
              </div>
            </template>
            <template #isEnable="{ record }">
              <a-switch
                :unCheckedValue="0"
                :checkedValue="1"
                v-model:checked="record.isEnable"
              ></a-switch>
            </template>
            <template #isDefault="{ record }">
              <a-switch
                :unCheckedValue="0"
                :checkedValue="1"
                v-model:checked="record.isDefault"
              ></a-switch>
            </template>
          </a-table>
        </div>
      </div>
      <div style="display: flex; justify-content: center">
        <a-button @click="save" type="primary">保存</a-button>
      </div>
    </a-card>
    <templateEdit
      v-model:visible="showEdit"
      :data="current"
      @done="reload"
    ></templateEdit>
  </div>
</template>

<script>
import templateEdit from './index-edit.vue'
import * as paymentApi from '@/api/payment/payment.js'
import * as templateApi from '@/api/payment/template.js'
export default {
  components: {
    templateEdit
  },
  data() {
    return {
      wechatTemplateList: [],
      alipayTemplateList: [],
      corpId: 0,
      showEdit: false,
      current: {},
      form: {
        mpWeixin: {
          client: 'mpWeixin',
          title: '在微信小程序端付款时使用',
          name: '微信小程序端',
          methods: [
            {
              client: 'mpWeixin',
              method: 'wechat',
              isMustTemplate: 1,
              isDefault: 0,
              isEnable: 0,
              others: '',
              templateId: 0
            },
            {
              client: 'mpWeixin',
              method: 'balance',
              isMustTemplate: 0,
              isDefault: 0,
              isEnable: 1,
              others: '',
              templateId: 0
            }
          ]
        },
        app: {
          client: 'app',
          title: '在APP端付款时使用',
          name: 'APP端',
          methods: [
            {
              client: 'app',
              method: 'wechat',
              isMustTemplate: 1,
              isDefault: 0,
              isEnable: 0,
              others: '',
              templateId: 0
            },
            {
              client: 'app',
              method: 'alipay',
              isMustTemplate: 1,
              isDefault: 0,
              isEnable: 0,
              others: '',
              templateId: 0
            },
            {
              client: 'app',
              method: 'balance',
              isMustTemplate: 0,
              isDefault: 0,
              isEnable: 1,
              others: '',
              templateId: 0
            }
          ]
        },
        h5: {
          client: 'h5',
          title: '在H5端付款时使用',
          name: 'H5端',
          methods: [
            {
              client: 'h5',
              method: 'wechat',
              isMustTemplate: 1,
              isDefault: 0,
              isEnable: 0,
              others: '',
              templateId: 0
            },
            {
              client: 'h5',
              method: 'alipay',
              isMustTemplate: 1,
              isDefault: 0,
              isEnable: 0,
              others: '',
              templateId: 0
            },
            {
              client: 'h5',
              method: 'balance',
              isMustTemplate: 0,
              isDefault: 0,
              isEnable: 1,
              others: '',
              templateId: 0
            }
          ]
        },
        h5Weixin: {
          client: 'h5Weixin',
          title: '在微信公众号端付款时使用',
          name: '微信公众号端',
          methods: [
            {
              client: 'h5Weixin',
              method: 'wechat',
              isMustTemplate: 1,
              isDefault: 0,
              isEnable: 0,
              others: '',
              templateId: 0
            },
            {
              client: 'h5Weixin',
              method: 'balance',
              isMustTemplate: 0,
              isDefault: 0,
              isEnable: 1,
              others: '',
              templateId: 0
            }
          ]
        }
      },
      columns: [
        {
          title: '支付方式',
          dataIndex: 'method',
          width: '350px',
          slots: {
            customRender: 'method'
          }
        },
        {
          title: '支付模板',
          width: '450px',
          dataIndex: 'isMustTemplate',
          slots: {
            customRender: 'isMustTemplate'
          }
        },
        {
          title: '是否启用',
          dataIndex: 'isEnable',
          width: '300px',
          slots: {
            customRender: 'isEnable'
          }
        },
        {
          title: '是否为默认支付',
          dataIndex: 'isDefault',
          width: '300px',
          slots: {
            customRender: 'isDefault'
          }
        }
      ]
    }
  },
  mounted() {
    this.corpId = this.getCorpId()
    this.getTemplateList()
    this.getPaymentRecord()
    console.log('corpId', this.corpId)
  },
  methods: {
    getPaymentRecord() {
      paymentApi
        .info(this.corpId)
        .then((res) => {
          if (res.code === 0) {
            this.form = Object.assign({}, this.form, res.data)
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    getTemplateList() {
      templateApi
        .page({corpId:this.getCorpId()})
        .then((res) => {
          if (res.code === 0) {
            const obj = { value: 0, label: '无' }
            this.wechatTemplateList = res.data
              .filter((val) => {
                return val.method === 'wechat'
              })
              .map((item) => {
                return {
                  value: item.templateId,
                  label: item.name
                }
              })
            this.wechatTemplateList.unshift(obj)
            this.alipayTemplateList = res.data
              .filter((val) => {
                return val.method === 'alipay'
              })
              .map((item) => {
                return {
                  value: item.templateId,
                  label: item.name
                }
              })
            this.alipayTemplateList.unshift(obj)
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    reload() {
      this.getTemplateList()
    },
    openEdit(data) {
      this.showEdit = true
      this.current = data
    },
    save() {
      this.form.corpId = this.corpId
      paymentApi
        .save(this.form, false)
        .then((res) => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.getPaymentRecord()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style lang="less" scoped>
.payment-item {
  display: flex;
  margin-bottom: 20px;
  .payment-client {
    width: 20%;
    border: 1px solid #e8e8e8;
    border-right: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 180px;
    justify-content: center;
    .client {
      font-weight: bold;
      font-size: 20px;
      color: #333;
    }
    .desc {
      color: #595959;
      margin-top: 20px;
    }
  }
  .payment-method {
    width: 80%;
    min-width: 800px;
  }
}
</style>
