import request from '@/utils/request'
// api地址
const api = {
  page: '/frm/corpPayment/page',
  info: '/frm/corpPayment/',
  save: '/frm/corpPayment',
  update: '/frm/corpPayment/update',
  delete: '/frm/corpPayment/',
  deleteBatch: '/frm/corpPayment/batch'
}

export const page = (params) => {
  return new Promise((resolve, reject) => {
    request.get(api.page,{params}).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 根据id查找
 * @param id id
 * @returns {Promise<>}
 */
export function info(id) {
  return new Promise((resolve, reject) => {
    request.get(api.info + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 根据id删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteById(id) {
  return new Promise((resolve, reject) => {
    request.delete(api.delete + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 批量删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteBatch(data) {
  return new Promise((resolve, reject) => {
    request.delete(api.deleteBatch, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function save(data) {
  return new Promise((resolve, reject) => {
    request.post(api.save, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
